import { useState, useEffect } from "react";
import { Spinner } from "flowbite-react";
import { useToast } from "../common/ToastContext";
import api from "../common/api";
import { getDateTimeAgo } from "../common/utils";

interface NotificationApiData {
  triggered_by: {
    email: string;
    role: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    profile: string;
  };
  title: string;
  body: string;
  created_at: string;
}

interface Notification {
  profile: string;
  title: string;
  body: string;
  time: string;
}

function NotificationDropdown() {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    async function getNotifications() {
      let apiResponse = { data: [] };
      try {
        setIsLoading(true);
        apiResponse = await api.get("/notifications");
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        showToast(
          "error",
          `Error retrieving notifications: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
        );
        setIsLoading(false);
      }
      const notifications: Notification[] = [];
      apiResponse.data.forEach(
        (notificationData: NotificationApiData, index: number) => {
          const notification: Notification = {
            profile: notificationData.triggered_by.profile,
            title: notificationData.title,
            body: notificationData.body,
            time: getDateTimeAgo(notificationData.created_at),
          };
          notifications.push(notification);
        },
      );
      setNotifications(notifications);
    }

    getNotifications();
  }, [showToast]);

  return (
    <div
      id="dropdownMenu"
      className="absolute -top-3 right-0 z-[1000] mt-2 block max-h-[500px] w-[410px] min-w-full overflow-auto rounded-lg bg-white py-4 shadow-lg dark:bg-gray-800"
    >
      <p className="mx-4 text-base font-semibold text-gray-800 dark:text-white">
        Notifications
      </p>

      {isLoading ? (
        <div className="mt-4 flex items-center justify-center p-6">
          <Spinner size="xl" />
        </div>
      ) : (
        <ul className="divide-y">
          {notifications.map((notification: Notification, index: number) => (
            <li
              key={index}
              className="flex cursor-pointer items-center p-4 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              <img
                src={`/profiles/${notification.profile}.jpg`}
                alt="Profile"
                className="size-12 shrink-0 rounded-full"
              />

              <div className="ml-6">
                <h3 className="text-sm font-semibold text-[#333] dark:text-slate-100">
                  {notification.title}
                </h3>
                <p className="mt-2 text-xs text-gray-500 dark:text-slate-200">
                  {notification.body}
                </p>
                <p className="mt-2 text-xs leading-3 text-blue-600">
                  {notification.time}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default NotificationDropdown;
