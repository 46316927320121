import { useState, useEffect, useMemo } from "react";
import { Badge } from "flowbite-react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "../common/ThemeContext";
import { useToast } from "../common/ToastContext";
import { titleCase } from "../common/utils";
import UserActionDropdown from "./UserActionDropdown";
import api from "../common/api";

interface RowData {
  id: string;
  email: string;
  role: string;
  firstName: string;
  middleName: string;
  lastName: string;
  profile: string;
}

interface UserApiData {
  id: number;
  email: string;
  role: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  profile: string;
}

function getBadgeColor(status: string): string {
  let color: string;

  switch (status) {
    case "user":
      color = "blue";
      break;
    case "staff":
      color = "indigo";
      break;
    case "officer":
      color = "lime";
      break;
    case "admin":
      color = "red";
      break;
    default:
      color = "blue";
  }
  return color;
}

interface UserTableProps {
  refresh: boolean;
}

function UserTable(props: UserTableProps) {
  const { theme } = useTheme();
  const { showToast } = useToast();
  const [rows, setRows] = useState<RowData[]>([]);

  useEffect(() => {
    async function setDataRows() {
      let apiResponse = { data: [] };
      try {
        apiResponse = await api.get("/users");
      } catch (error: any) {
        console.log(error);
        showToast(
          "error",
          `Error retrieving applications: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
        );
      }
      const datarows: RowData[] = [];
      apiResponse.data.forEach((user: UserApiData, index: number) => {
        const rowData: RowData = {
          id: user.id.toString(),
          email: user.email,
          role: user.role,
          firstName: user.first_name,
          middleName: user.middle_name,
          lastName: user.last_name,
          profile: user.profile,
        };
        datarows.push(rowData);
      });
      setRows(datarows);
    }

    setDataRows();
  }, [showToast, props.refresh]);

  const handleUpdateUserRole = (id: string, role: string) => {
    setRows((prevRows) =>
      prevRows?.map((row) => (row.id === id ? { ...row, role: role } : row)),
    );
  };

  const handleDeleteUser = (id: string) => {
    setRows(
      (prevRows) => prevRows?.filter((rowItem) => rowItem.id !== id) || [],
    );
  };

  const columns: GridColDef<RowData>[] = useMemo(() => {
    const baseColumns: GridColDef<RowData>[] = [
      {
        field: "id",
        headerName: "User ID",
        width: 200,
        editable: false,
      },
      {
        field: "email",
        headerName: "Account Details",
        width: 400,
        editable: false,
        renderCell: (params) => {
          const { firstName, lastName, email, profile } = params.row;
          const fullName = `${firstName} ${lastName}`;

          return (
            <div className="flex size-full items-center">
              <div className="flex w-full flex-row items-center space-x-4 rounded-lg p-1.5">
                <img
                  src={`/profiles/${profile}.jpg`}
                  alt="Profile"
                  className="size-10 rounded-full"
                />
                <div className="flex flex-col items-start justify-center">
                  <p className="text-sm font-semibold text-gray-800 dark:text-slate-100">
                    {fullName}
                  </p>
                  <p className="text-xs font-normal text-gray-600 dark:text-slate-200">
                    {email}
                  </p>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        field: "role",
        headerName: "Role",
        headerAlign: "center",
        width: 150,
        editable: false,
        renderCell: (params) => (
          <div className="flex size-full items-center justify-center">
            <Badge
              color={getBadgeColor(params.value)}
              size="xs"
              className="rounded-lg px-3 py-1.5"
            >
              {titleCase(params.value)}
            </Badge>
          </div>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <div className="flex size-full justify-end">
            <UserActionDropdown
              row={params.row}
              onUpdate={handleUpdateUserRole}
              onDelete={handleDeleteUser}
            />
          </div>
        ),
      },
    ];
    return baseColumns;
  }, []);

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      sx={{
        "& .MuiDataGrid-cell": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
        "& .MuiDataGrid-toolbarQuickFilter .MuiInputBase-inputTypeSearch": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
        "& .MuiDataGrid-toolbarQuickFilter .MuiSvgIcon-root": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
        "& .MuiTablePagination-displayedRows": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
        "& .MuiTablePagination-actions > button": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
      }}
      initialState={{
        density: "comfortable",
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
    />
  );
}

export default UserTable;
export type { RowData };
