import { useState, useEffect } from "react";
import { Modal, Button } from "flowbite-react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

interface UserDeleteModalProps {
  show: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}

function UserDeleteModal(props: UserDeleteModalProps) {
  const [showModal, setShowModal] = useState(props.show);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  return (
    <Modal show={showModal} size="md" onClose={props.onDismiss} popup>
      <Modal.Header />
      <Modal.Body>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
            <ExclamationTriangleIcon className="size-6 text-red-600" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3
              className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
              id="modal-title"
            >
              Confirm Action
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500 dark:text-slate-200">
                This action cannot be undo. Are you sure you want to delete this
                user?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-2 flex flex-row justify-center space-x-4 px-6 py-3 lg:justify-end">
          <Button color="gray" onClick={props.onDismiss}>
            Cancel
          </Button>
          <Button color="failure" onClick={props.onConfirm}>
            Proceed
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserDeleteModal;
