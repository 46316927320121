import { useState, useEffect } from "react";
import { Modal, Label, Select, Button, TextInput } from "flowbite-react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useToast } from "../common/ToastContext";
import api from "../common/api";

interface UserFormData {
  email: string;
  role: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  middleName: string;
  lastName: string;
}

const initialUserFormData: UserFormData = {
  email: "",
  role: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  middleName: "",
  lastName: "",
};

interface UserCreateModalProps {
  show: boolean;
  onDismiss: () => void;
  onFormSubmitSuccess: () => void;
  onFormSubmitFailure: () => void;
}

function UserCreateModal(props: UserCreateModalProps) {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(props.show);
  const [userFormData, setUserFormData] =
    useState<UserFormData>(initialUserFormData);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleDismiss = () => {
    setUserFormData(initialUserFormData);
    props.onDismiss();
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    setUserFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (userFormData.password !== userFormData.confirmPassword) {
      showToast("error", "Passwords do not match");
      return;
    }

    const formData = new FormData();
    formData.append("username", userFormData.email);
    formData.append("password", userFormData.password);
    formData.append("confirm_password", userFormData.confirmPassword);
    formData.append("role", userFormData.role);
    formData.append("first_name", userFormData.firstName);
    formData.append("middle_name", userFormData.middleName);
    formData.append("last_name", userFormData.lastName);

    // console.log(Array.from(formData.entries()));

    try {
      setIsLoading(true);
      await api.post("/user", formData);
      setIsLoading(false);
      showToast("success", "User created successfully!");
      setUserFormData(initialUserFormData);
      props.onFormSubmitSuccess();
    } catch (error: any) {
      console.log(error);
      showToast(
        "error",
        `Error creating user: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
      );
      setUserFormData(initialUserFormData);
      setIsLoading(false);
      props.onFormSubmitFailure();
    }
  };

  return (
    <Modal show={showModal} size="2xl" onClose={handleDismiss} popup>
      <Modal.Header className="px-6 py-4">Create a New User</Modal.Header>
      <Modal.Body>
        <form
          onSubmit={submitForm}
          className="grid grid-cols-1 gap-8 md:grid-cols-2"
        >
          <div className="grid md:col-span-2">
            <p className="text-base font-normal text-gray-700 dark:text-white">
              Create a new user directly. Each role comes with specific
              permissions:
            </p>
            <ul className="ml-5 list-disc text-base font-normal text-gray-700 dark:text-white">
              <li>
                <span className="font-semibold">User: </span>
                Basic access to features and services. Can apply for
                applications.
              </li>
              <li>
                <span className="font-semibold">Staff: </span>
                Access to additional tools for managing content and application
                interactions.
              </li>
              <li>
                <span className="font-semibold">Officer: </span>
                Access to additional application settings such as application
                approval or rejection.
              </li>
            </ul>
          </div>
          <div>
            <Label htmlFor="firstName" value="First name" className="mb-2" />
            <TextInput
              id="firstName"
              name="firstName"
              type="text"
              value={userFormData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="lastName" value="Last name" className="mb-2" />
            <TextInput
              id="lastName"
              name="lastName"
              type="text"
              value={userFormData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="middleName" value="Middle name" className="mb-2" />
            <TextInput
              id="middleName"
              name="middleName"
              type="text"
              value={userFormData.middleName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="hidden md:grid"></div>
          <div>
            <Label htmlFor="email" value="Email address" className="mb-2" />
            <TextInput
              id="email"
              name="email"
              type="text"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}"
              placeholder="johndoe@example.com"
              title="Please enter a valid email address in the format: johndoe@example.com"
              value={userFormData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="role" value="Role" className="mb-2" />
            <Select
              id="role"
              name="role"
              required
              value={userFormData.role}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select role
              </option>
              <option value="user">User</option>
              <option value="staff">Staff</option>
              <option value="officer">Officer</option>
            </Select>
          </div>
          <div>
            <Label htmlFor="password" value="Password" className="mb-2" />
            <TextInput
              id="password"
              name="password"
              type="password"
              value={userFormData.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label
              htmlFor="confirmPassword"
              value="Confirm password"
              className="mb-2"
            />
            <TextInput
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={userFormData.confirmPassword}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="grid justify-items-center md:col-span-2">
            <Button color="blue" type="submit" isProcessing={isLoading}>
              <PlusIcon className="mr-2 size-5" />
              Create new user
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default UserCreateModal;
