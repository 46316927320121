import { useState, useEffect } from "react";
import { Button, Modal } from "flowbite-react";
import {
  ExclamationTriangleIcon,
  BackwardIcon,
  ForwardIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/solid";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { useToast } from "../common/ToastContext";
import AddApplicationStep1 from "./AddApplicationStep1";
import AddApplicationStep2 from "./AddApplicationStep2";
import AddApplicationStep3 from "./AddApplicationStep3";
import AddApplicationStep4 from "./AddApplicationStep4";
import api from "../common/api";

interface ApplicationFormData {
  applicationType: string;
  clientCategory: string;
  beneficiaryCategory: string;
  beneficiaryRelationship: string;
  assistanceCategory: string;
  amount: number;
  financialMode: string;
  assistanceSource: string;
  purpose: string;
  beneficiaryFirstName: string;
  beneficiaryMiddleName: string;
  beneficiaryLastName: string;
  beneficiaryBarangay: string;
  beneficiaryCity: string;
  beneficiaryProvince: string;
  beneficiaryRegion: string;
  beneficiaryBirthdate: string;
  beneficiarySex: string;
  beneficiaryStatus: string;
  beneficiaryContact: string;
  beneficiaryOccupation: string;
  beneficiaryIncome: number;
  files: { [key: string]: File };
}

const initialApplicationFormData: ApplicationFormData = {
  applicationType: "",
  clientCategory: "",
  beneficiaryCategory: "",
  beneficiaryRelationship: "",
  assistanceCategory: "",
  amount: 0,
  financialMode: "",
  assistanceSource: "",
  purpose: "",
  beneficiaryFirstName: "",
  beneficiaryMiddleName: "",
  beneficiaryLastName: "",
  beneficiaryBarangay: "",
  beneficiaryCity: "",
  beneficiaryProvince: "",
  beneficiaryRegion: "",
  beneficiaryBirthdate: new Date().toISOString().split("T")[0],
  beneficiarySex: "",
  beneficiaryStatus: "",
  beneficiaryContact: "",
  beneficiaryOccupation: "",
  beneficiaryIncome: 0,
  files: {},
};

const requirements = {
  burial_assistance: [
    "certificate_of_indigence",
    "valid_id",
    "funeral_contract",
  ],
  education_assistance: [
    "certificate_of_enrollment",
    "barangay_certificate",
    "certificate_of_indigence",
    "valid_id",
    "endorsement_letter",
  ],
  livelihood_assistance: [
    "certificate_of_indigence",
    "valid_id",
    "mayors_permit",
    "certificate_of_recommendation",
  ],
  medical_assistance: [
    "certificate_of_indigence",
    "medical_certificate",
    "prescription_of_medicine_or_laboratory_request",
  ],
  philhealth_certification: [
    "request_for_certificate_of_financial_assessment",
    "certificate_of_indigence",
    "philhealth_form",
    "birth_certificate",
    "medical_certificate",
  ],
  transportation_assistance: ["certificate_of_indigence"],
};

function validateApplicationFormData(data: ApplicationFormData): boolean {
  if (
    !data.applicationType ||
    !data.clientCategory ||
    !data.beneficiaryCategory ||
    !data.beneficiaryRelationship ||
    !data.assistanceCategory ||
    data.amount === 0 ||
    !data.financialMode ||
    !data.assistanceSource ||
    !data.purpose
  ) {
    return false;
  }

  return true;
}

function validateApplicationDocuments(data: ApplicationFormData): boolean {
  if (data.assistanceCategory in requirements) {
    const requiredDocuments =
      requirements[data.assistanceCategory as keyof typeof requirements] || [];
    for (let document in requiredDocuments) {
      if (!(requiredDocuments[document] in data.files)) {
        return false;
      }
    }
  }
  return true;
}

function validateBeneficiaryFormData(data: ApplicationFormData): boolean {
  if (
    !data.beneficiaryFirstName ||
    !data.beneficiaryMiddleName ||
    !data.beneficiaryLastName ||
    !data.beneficiaryBarangay ||
    !data.beneficiaryCity ||
    !data.beneficiaryProvince ||
    !data.beneficiaryRegion ||
    !data.beneficiaryBirthdate ||
    !data.beneficiarySex ||
    !data.beneficiaryStatus ||
    !data.beneficiaryContact ||
    !data.beneficiaryOccupation
  ) {
    return false;
  }

  return true;
}

interface AddApplicationModalProps {
  show: boolean;
  onDismiss: () => void;
  onFormSubmitSuccess: () => void;
  onFormSubmitFailure: () => void;
}

function AddApplicationModal(props: AddApplicationModalProps) {
  const [showModal, setShowModal] = useState(props.show);
  const [currentStep, setCurrentStep] = useState(1);
  const [amountError, setAmountError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [contactError, setContactError] = useState("");
  const [incomeError, setIncomeError] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [showConfirmDocumentModal, setShowConfirmDocumentModal] =
    useState(false);
  const [applicationFormData, setApplicationFormData] =
    useState<ApplicationFormData>(initialApplicationFormData);
  const { showToast } = useToast();
  const totalSteps = 5;

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    if (name === "amount") {
      const incomeRegex = /^(?!0$)\d+$/;
      if (!incomeRegex.test(value)) {
        setAmountError("Please enter a valid amount (digits only but not 0)");
      } else {
        setAmountError("");
      }
    }

    if (name === "beneficiaryContact") {
      const contactRegex = /^[0-9]{10,15}$/;
      if (!contactRegex.test(value)) {
        setContactError("Please enter a valid contact number (10-15 digits).");
      } else {
        setContactError("");
      }
    }

    if (name === "beneficiaryIncome") {
      const incomeRegex = /^\d+$/;
      if (!incomeRegex.test(value)) {
        setIncomeError("Please enter a valid income (digits only)");
      } else {
        setIncomeError("");
      }
    }

    setApplicationFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBirthdateChange = (date: string | Date | null) => {
    if (date) {
      setApplicationFormData((prevData) => ({
        ...prevData,
        beneficiaryBirthdate:
          typeof date === "string" ? date : date.toISOString().split("T")[0],
      }));
      setBirthdateError("");
    }
  };

  const handleFileInputChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>,
    formData: ApplicationFormData,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setApplicationFormData({
        ...formData,
        files: {
          ...formData.files,
          [key]: files[0],
        },
      });
    }
  };

  const handleFileDelete = (key: string) => {
    setApplicationFormData((prevState) => {
      const updatedFiles = { ...prevState.files };
      delete updatedFiles[key];
      return {
        ...prevState,
        files: updatedFiles,
      };
    });
  };

  const handleDeleteAllFiles = () => {
    setApplicationFormData((prevState) => ({
      ...prevState,
      files: {},
    }));
  };

  const nextStep = () => {
    if (currentStep < totalSteps) {
      if (
        currentStep === 1 &&
        !validateApplicationFormData(applicationFormData)
      ) {
        alert("Please complete the application information");
        return;
      }
      if (
        currentStep === 2 &&
        !validateBeneficiaryFormData(applicationFormData)
      ) {
        alert("Please complete the beneficiary information");
        return;
      }
      if (
        currentStep === 3 &&
        !validateApplicationDocuments(applicationFormData)
      ) {
        alert("Please upload neccessary files");
        return;
      }
      if (currentStep === 4) {
        submitForm();
        return;
      }
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = (bypass: boolean = false) => {
    if (currentStep >= 3) {
      if (!bypass) {
        setShowConfirmDocumentModal(true);
        return;
      }
      handleDeleteAllFiles();
    }
    setShowConfirmDocumentModal(false);
    setCurrentStep((prev) => prev - 1);
  };

  const submitForm = async () => {
    const formData = new FormData();
    formData.append("application_type", applicationFormData.applicationType);
    formData.append(
      "assistance_category",
      applicationFormData.assistanceCategory,
    );
    formData.append("client_category", applicationFormData.clientCategory);
    formData.append(
      "beneficiary_category",
      applicationFormData.beneficiaryCategory,
    );
    formData.append(
      "beneficiary_relationship",
      applicationFormData.beneficiaryRelationship,
    );
    formData.append("financial_mode", applicationFormData.financialMode);
    formData.append("assistance_source", applicationFormData.assistanceSource);
    formData.append("amount", applicationFormData.amount.toString());
    formData.append("purpose", applicationFormData.purpose);
    formData.append(
      "beneficiary_first_name",
      applicationFormData.beneficiaryFirstName,
    );
    formData.append(
      "beneficiary_middle_name",
      applicationFormData.beneficiaryMiddleName,
    );
    formData.append(
      "beneficiary_last_name",
      applicationFormData.beneficiaryLastName,
    );
    formData.append(
      "beneficiary_barangay",
      applicationFormData.beneficiaryBarangay,
    );
    formData.append("beneficiary_city", applicationFormData.beneficiaryCity);
    formData.append(
      "beneficiary_province",
      applicationFormData.beneficiaryProvince,
    );
    formData.append(
      "beneficiary_region",
      applicationFormData.beneficiaryRegion,
    );
    formData.append(
      "beneficiary_birthdate",
      applicationFormData.beneficiaryBirthdate,
    );
    formData.append("beneficiary_sex", applicationFormData.beneficiarySex);
    formData.append(
      "beneficiary_status",
      applicationFormData.beneficiaryStatus,
    );
    formData.append(
      "beneficiary_contact",
      applicationFormData.beneficiaryContact,
    );
    formData.append(
      "beneficiary_occupation",
      applicationFormData.beneficiaryOccupation,
    );
    formData.append(
      "beneficiary_income",
      applicationFormData.beneficiaryIncome.toString(),
    );

    Object.keys(applicationFormData.files).forEach((key) => {
      formData.append("document_files", applicationFormData.files[key]);
      formData.append("document_types", key);
    });

    // console.log(Array.from(formData.entries()));

    try {
      setIsLoading(true);
      await api.post("/application", formData);
      setIsLoading(false);
      showToast("success", "Application created successfully!");
      setApplicationFormData(initialApplicationFormData);
      setCurrentStep(1);
      props.onFormSubmitSuccess();
    } catch (error: any) {
      console.log(error);
      showToast(
        "error",
        `Error creating application: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
      );
      setApplicationFormData(initialApplicationFormData);
      setIsLoading(false);
      setCurrentStep(1);
      props.onFormSubmitFailure();
    }
  };

  const handleDismiss = () => {
    setCurrentStep(1);
    props.onDismiss();
  };

  return (
    <Modal show={showModal} size="5xl" onClose={handleDismiss} popup>
      <Modal.Header className="px-6 pt-4">Apply for Assistances</Modal.Header>
      <Modal.Body>
        <div className="container mx-auto mt-4 lg:mt-8">
          <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-y-0">
            <div className="flex flex-row space-x-2">
              <CheckBadgeIcon
                className={`size-5 fill-blue-600 ${currentStep > 1 ? "flex" : "hidden"}`}
              />
              <p
                className={`text-base font-medium leading-tight ${currentStep > 1 ? "text-blue-600" : "dark:text-white"}`}
              >
                Information
              </p>
            </div>
            <hr
              className={`mx-4 hidden grow border-t md:flex ${currentStep > 1 ? "border-blue-600" : "border-gray-400"} `}
            />
            <div className="flex flex-row space-x-2">
              <CheckBadgeIcon
                className={`size-5 fill-blue-600 ${currentStep > 2 ? "flex" : "hidden"}`}
              />
              <p
                className={`text-base font-medium leading-tight ${currentStep > 2 ? "text-blue-600" : "dark:text-white"}`}
              >
                Beneficiary
              </p>
            </div>
            <hr
              className={`mx-4 hidden grow border-t md:flex ${currentStep > 2 ? "border-blue-600" : "border-gray-400"} `}
            />
            <div className="flex flex-row space-x-2">
              <CheckBadgeIcon
                className={`size-5 fill-blue-600 ${currentStep > 3 ? "flex" : "hidden"}`}
              />
              <p
                className={`text-base font-medium leading-tight ${currentStep > 3 ? "text-blue-600" : "dark:text-white"}`}
              >
                Requirements
              </p>
            </div>
            <hr
              className={`mx-4 hidden grow border-t md:flex ${currentStep > 3 ? "border-blue-600" : "border-gray-400"} `}
            />

            <div className="flex flex-row space-x-2">
              <p className="ml-2 text-base font-medium leading-tight dark:text-white md:ml-0">
                Review
              </p>
            </div>
          </div>
          <div className="mt-8">
            {currentStep === 1 && (
              <AddApplicationStep1
                applicationFormData={applicationFormData}
                handleInputChange={handleInputChange}
                amountError={amountError}
              />
            )}
            {currentStep === 2 && (
              <AddApplicationStep2
                applicationFormData={applicationFormData}
                handleInputChange={handleInputChange}
                handleBirthdateChange={handleBirthdateChange}
                birthdateError={birthdateError}
                contactError={contactError}
                incomeError={incomeError}
              />
            )}
            {currentStep === 3 && (
              <AddApplicationStep3
                applicationFormData={applicationFormData}
                handleFileInputChange={handleFileInputChange}
                handleFileDelete={handleFileDelete}
              />
            )}
            {currentStep === 4 && (
              <AddApplicationStep4 applicationFormData={applicationFormData} />
            )}
          </div>

          <div className="mt-8 flex justify-between">
            <Button
              color="blue"
              onClick={(e: any) => prevStep()}
              disabled={currentStep === 1}
            >
              <BackwardIcon className="mr-2 size-5" />
              Previous
            </Button>
            <Button
              color="blue"
              onClick={nextStep}
              disabled={currentStep === totalSteps}
              isProcessing={isloading}
            >
              {currentStep <= 3 ? "Next" : "Submit"}
              {currentStep <= 3 ? (
                <ForwardIcon className="ml-2 size-5" />
              ) : (
                <DocumentPlusIcon className="ml-2 size-5" />
              )}
            </Button>
          </div>
          <Modal
            show={showConfirmDocumentModal}
            size="md"
            onClose={() => setShowConfirmDocumentModal(false)}
            popup
          >
            <Modal.Header />
            <Modal.Body>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                  <ExclamationTriangleIcon className="size-6 text-red-600"></ExclamationTriangleIcon>
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                    id="modal-title"
                  >
                    Document requirements will be deleted
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 dark:text-slate-300">
                      Proceeding to go back will result in uploading documents
                      again. Continue?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-2 flex flex-row justify-center space-x-4 px-6 py-3 lg:justify-end">
                <Button
                  color="gray"
                  onClick={() => setShowConfirmDocumentModal(false)}
                >
                  Cancel
                </Button>
                <Button color="failure" onClick={(e: any) => prevStep(true)}>
                  Continue
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddApplicationModal;
export type { ApplicationFormData };
