import { Datepicker, Label, Select, TextInput } from "flowbite-react";
import { ApplicationFormData } from "./AddApplicationModal";

interface ApplicationStepContentProps {
  applicationFormData: ApplicationFormData;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleBirthdateChange: (e: string | Date | null) => void;
  birthdateError: string;
  contactError: string;
  incomeError: string;
}

function AddApplicationStep2({
  applicationFormData,
  handleInputChange,
  handleBirthdateChange,
  birthdateError,
  contactError,
  incomeError,
}: ApplicationStepContentProps) {
  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      <div className="grid md:col-span-2">
        <p className="text-base font-normal text-gray-500 dark:text-slate-300">
          Enter beneficiary information. This is important for us to know the
          eligibility of the application
        </p>
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-semibold text-gray-700">
          Personal Information
        </p>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryFirstName"
          value="First name"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryFirstName"
          name="beneficiaryFirstName"
          type="text"
          value={applicationFormData.beneficiaryFirstName}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryLastName"
          value="Last name"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryLastName"
          name="beneficiaryLastName"
          type="text"
          value={applicationFormData.beneficiaryLastName}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryMiddleName"
          value="Middle name"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryMiddleName"
          name="beneficiaryMiddleName"
          type="text"
          value={applicationFormData.beneficiaryMiddleName}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="beneficiarySex" value="Gender" className="mb-2" />
        <Select
          id="beneficiarySex"
          name="beneficiarySex"
          required
          value={applicationFormData.beneficiarySex}
          onChange={handleInputChange}
        >
          <option value="" disabled>
            Select your gender
          </option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Prefer not to say">Prefer not to say</option>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryBirthdate"
          value="Birthdate"
          className="mb-2"
        />
        <Datepicker
          id="beneficiaryBirthdate"
          name="beneficiaryBirthdate"
          onSelectedDateChanged={(e) => handleBirthdateChange(e)}
          onChange={(e) => handleBirthdateChange(e.target.value)}
          required
          color={birthdateError ? "failure" : undefined}
        />
        {birthdateError && (
          <p className="mt-1 text-sm text-red-600">{birthdateError}</p>
        )}
      </div>
      <div>
        <Label
          htmlFor="beneficiaryStatus"
          value="Civil status"
          className="mb-2"
        />
        <Select
          id="beneficiaryStatus"
          name="beneficiaryStatus"
          required
          value={applicationFormData.beneficiaryStatus}
          onChange={handleInputChange}
        >
          <option value="" disabled>
            Select civil status
          </option>
          <option value="Single">Single</option>
          <option value="Married">Married</option>
          <option value="Separated">Separated</option>
          <option value="Divorced">Divorced</option>
          <option value="Widow">Widow</option>
          <option value="Prefer not to say">Prefer not to say</option>
        </Select>
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-semibold text-gray-700">
          Contact Information
        </p>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryContact"
          value="Contact Number"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryContact"
          name="beneficiaryContact"
          type="text"
          value={applicationFormData.beneficiaryContact}
          onChange={handleInputChange}
          required
          color={contactError ? "failure" : undefined}
        />
        {contactError && (
          <p className="mt-1 text-sm text-red-600">{contactError}</p>
        )}
      </div>
      <div>
        <Label
          htmlFor="beneficiaryBarangay"
          value="Barangay"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryBarangay"
          name="beneficiaryBarangay"
          type="text"
          value={applicationFormData.beneficiaryBarangay}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryCity"
          value="City / Municipality"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryCity"
          name="beneficiaryCity"
          type="text"
          value={applicationFormData.beneficiaryCity}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryProvince"
          value="Province / District"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryProvince"
          name="beneficiaryProvince"
          type="text"
          value={applicationFormData.beneficiaryProvince}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="beneficiaryRegion" value="Region" className="mb-2" />
        <TextInput
          id="beneficiaryRegion"
          name="beneficiaryRegion"
          type="text"
          value={applicationFormData.beneficiaryRegion}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-semibold text-gray-700">
          Occupational Information
        </p>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryOccupation"
          value="Job / Occupation"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryOccupation"
          name="beneficiaryOccupation"
          type="text"
          value={applicationFormData.beneficiaryOccupation}
          onChange={handleInputChange}
          required
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryIncome"
          value="Monthly income"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryIncome"
          name="beneficiaryIncome"
          type="text"
          value={applicationFormData.beneficiaryIncome}
          onChange={handleInputChange}
          required
          color={incomeError ? "failure" : undefined}
        />
        {incomeError && (
          <p className="mt-1 text-sm text-red-600">{incomeError}</p>
        )}
      </div>
    </div>
  );
}

export default AddApplicationStep2;
