import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../common/AuthContext";

function LogoutPage() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    navigate("/login");
  });

  return <></>;
}

export default LogoutPage;
