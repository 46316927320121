function titleCase(str: string) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

function snakeToTitleCase(snakeCaseStr: string): string {
  return snakeCaseStr
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

function snakeToUpperCase(snakeCaseStr: string): string {
  let formattedStr = snakeCaseStr.replace(/_/g, " ");
  formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
  return formattedStr;
}

function toSnakeCase(inputStr: string): string {
  inputStr = inputStr.replace(/[\s-]+/g, "_");
  const snakeCaseStr = inputStr.replace(/([a-z])([A-Z])/g, "$1_$2");
  return snakeCaseStr.toLowerCase();
}

function getDateTimeAgo(dateString: string): string {
  const pastDate = new Date(dateString);
  const now = new Date();
  const differenceMs = now.getTime() - pastDate.getTime();

  const minutes = Math.floor(differenceMs / (1000 * 60));
  const hours = Math.floor(differenceMs / (1000 * 60 * 60));
  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(differenceMs / (1000 * 60 * 60 * 24 * 7));

  if (weeks > 1) {
    return `${weeks} weeks ago`;
  } else if (weeks === 1) {
    return "1 week ago";
  } else if (days > 1) {
    return `${days} days ago`;
  } else if (days === 1) {
    return "1 day ago";
  } else if (hours > 1) {
    return `${hours} hours ago`;
  } else if (hours === 1) {
    return "1 hour ago";
  } else if (minutes > 1) {
    return `${days} minutes ago`;
  } else {
    return "1 minute ago";
  }
}

const colors = [
  "#ef4444",
  "#f59e0b",
  "#4d7c0f",
  "#15803d",
  "#0891b2",
  "#0ea5e9",
  "#3b82f6",
  "#6366f1",
  "#7e22ce",
  "#f43f5e",
];

function stringToColor(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % colors.length;
  return colors[index];
}

export {
  titleCase,
  snakeToTitleCase,
  snakeToUpperCase,
  toSnakeCase,
  getDateTimeAgo,
  stringToColor,
};
