import React from "react";
import { Link } from "react-router-dom";

interface LinkIconProps {
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
}

const LinkIcon: React.FC<LinkIconProps> = ({ href, icon: Icon, children }) => {
  return (
    <Link to={href} className="flex items-center text-blue-600 hover:underline">
      {children}
      <Icon className="ml-2 size-4" />
    </Link>
  );
};

export default LinkIcon;
