import { format, parseISO } from "date-fns";
import { useState, useEffect, useMemo } from "react";
import { Badge } from "flowbite-react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useAuth } from "../common/AuthContext";
import { UserRole } from "../common/enums";
import { titleCase, snakeToTitleCase } from "../common/utils";
import { useToast } from "../common/ToastContext";
import { useTheme } from "../common/ThemeContext";
import ApplicationActionDropdown from "./ApplicationActionDropdown";
import api from "../common/api";

interface RowData {
  id: number;
  applicationNumber: string;
  applicantName: string;
  applicationDate: string;
  applicationType: string;
  assistanceCategory: string;
  amount: number;
  status: string;
  interviewLocation: string;
  interviewDatetime: string;
}

interface UserApiData {
  email: string;
  role: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  birthdate?: string;
  birthplace?: string;
  contact?: string;
  education?: string;
  occupation?: string;
  income?: string;
  philhealth?: string;
  religion?: string;
  sex?: string;
  status?: string;
}

interface ApplicationApiData {
  uuid: string;
  application_type: string;
  assistance_category: string;
  amount: number;
  assistance_source: string;
  client_category: string;
  created_at: string;
  financial_mode: string;
  updated_at: string;
  user: UserApiData;
  status: string;
  interview_location?: string;
  interview_at?: string;
}

interface ApplicationTableProps {
  refresh: boolean;
}

function getBadgeColor(status: string): string {
  let color: string;

  switch (status) {
    case "submitted":
      color = "blue";
      break;
    case "canceled":
      color = "gray";
      break;
    case "interview":
      color = "green";
      break;
    case "approved":
      color = "lime";
      break;
    case "rejected":
      color = "red";
      break;
    case "released":
      color = "indigo";
      break;
    case "completed":
      color = "green";
      break;
    default:
      color = "blue";
  }

  return color;
}

function ApplicationTable({ refresh }: ApplicationTableProps) {
  const { userdata } = useAuth();
  const { showToast } = useToast();
  const [rows, setRows] = useState<RowData[]>();
  const { theme } = useTheme();

  const updateRowStatus = (applicationNumber: string, newStatus: string) => {
    setRows((prevRows) =>
      prevRows?.map((row) =>
        row.applicationNumber === applicationNumber
          ? { ...row, status: newStatus }
          : row,
      ),
    );
  };

  const handleDeleteRow = (applicationNumber: string) => {
    setRows(
      (prevRows) =>
        prevRows?.filter(
          (rowItem) => rowItem.applicationNumber !== applicationNumber,
        ) || [],
    );
  };

  const columns: GridColDef<RowData>[] = useMemo(() => {
    const baseColumns: GridColDef<RowData>[] = [
      {
        field: "applicationNumber",
        headerName: "Application Number",
        width: 200,
        editable: false,
      },
      {
        field: "applicationDate",
        headerName: "Application Date",
        width: 250,
        editable: false,
        valueGetter: (value, row) => {
          const date = parseISO(row.applicationDate);
          const formattedDate = format(date, "MMMM dd, yyyy");
          return formattedDate;
        },
      },
      {
        field: "applicationType",
        headerName: "Application Type",
        width: 250,
        editable: false,
        valueGetter: (value, row) => snakeToTitleCase(row.applicationType),
      },
      {
        field: "assistanceCategory",
        headerName: "Assistance Category",
        width: 250,
        editable: false,
        valueGetter: (value, row) => snakeToTitleCase(row.assistanceCategory),
      },
      {
        field: "status",
        headerName: "Application Status",
        width: 180,
        editable: false,
        renderCell: (params) => (
          <div className="flex size-full items-center justify-center">
            <Badge
              color={getBadgeColor(params.value)}
              size="xs"
              className="rounded-lg px-3 py-1.5"
            >
              {titleCase(params.value)}
            </Badge>
          </div>
        ),
      },
      {
        field: "amount",
        headerName: "Amount",
        type: "number",
        width: 150,
        editable: false,
        valueGetter: (value, row) => `₱${row.amount || ""}`,
      },
      {
        field: "interview",
        headerName: "Scheduled Interview",
        width: 340,
        editable: false,
        valueGetter: (value, row) => {
          if (!row.interviewDatetime) {
            return "No interview scheduled";
          }
          const date = parseISO(row.applicationDate);
          const formattedDate = format(date, "hh:mm a 'at' MMMM dd, yyyy");
          return `${formattedDate} on ${row.interviewLocation}`;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        width: 180,
        sortable: false,
        renderCell: (params) => (
          <div className="flex size-full justify-end">
            <ApplicationActionDropdown
              row={params.row}
              updateRowStatus={updateRowStatus}
              onDelete={handleDeleteRow}
            />
          </div>
        ),
      },
    ];

    if (
      userdata &&
      [UserRole.staff, UserRole.officer, UserRole.admin].includes(
        userdata.role as UserRole,
      )
    ) {
      baseColumns.splice(1, 0, {
        field: "applicantName",
        headerName: "Applicant Name",
        width: 200,
        editable: false,
      });
    }

    return baseColumns;
  }, [userdata]);

  useEffect(() => {
    async function setDataRows() {
      let apiResponse = { data: [] };
      try {
        apiResponse = await api.get("/applications");
      } catch (error: any) {
        console.log(error);
        showToast(
          "error",
          `Error retrieving applications: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
        );
      }
      const datarows: RowData[] = [];
      apiResponse.data.forEach(
        (application: ApplicationApiData, index: number) => {
          const rowData: RowData = {
            id: index,
            applicationNumber: application.uuid,
            applicantName: `${application.user.first_name || "Unknown"} ${application.user.last_name || "User"}`,
            applicationDate: application.created_at,
            applicationType: application.application_type,
            assistanceCategory: application.assistance_category,
            amount: application.amount,
            status: application.status,
            interviewLocation: application.interview_location || "",
            interviewDatetime: application.interview_at || "",
          };
          datarows.push(rowData);
        },
      );
      setRows(datarows);
    }

    setDataRows();
  }, [showToast, refresh]);

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      sx={{
        minHeight: 430,
        "& .MuiDataGrid-virtualScrollerContent": {
          position: "relative",
          zIndex: 9999,
          overflowY: "visible",
        },
        "& .MuiDataGrid-cell": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
        "& .MuiDataGrid-toolbarQuickFilter .MuiInputBase-inputTypeSearch": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
        "& .MuiDataGrid-toolbarQuickFilter .MuiSvgIcon-root": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
        "& .MuiTablePagination-displayedRows": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
        "& .MuiTablePagination-actions > button": {
          color: theme === "dark" ? "#e0e0e0" : "#000000",
        },
      }}
      initialState={{
        density: "standard",
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      // autoHeight={true}
    />
  );
}

export default ApplicationTable;
export type { RowData };
