import { useState } from "react";
import { Button } from "flowbite-react";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import NavigationBar from "../components/NavigationBar";
import UserCreateModal from "../components/UserCreateModal";
import UserTable from "../components/UserTable";

function UsersPage() {
  const [refresh, setRefresh] = useState(false);
  const [showUserCreateModal, setShowUserCreateModal] = useState(false);

  const handleAddApplicationSubmitSuccess = () => {
    setShowUserCreateModal(false);
    setRefresh(!refresh);
  };

  return (
    <main className="flex min-h-screen flex-col dark:bg-gray-800">
      <NavigationBar />
      <div className="container mx-auto flex min-h-screen flex-col justify-start space-y-8 p-6 lg:pt-14">
        <div className="flex w-full flex-col space-y-2">
          <p className="text-xl font-extrabold text-gray-900 dark:text-white lg:text-3xl">
            User Management Dashboard
          </p>
          <p className="text-base font-normal text-gray-500 dark:text-slate-100 lg:text-lg">
            Manage and oversee user accounts within the system. View, edit, and
            control user profiles, roles, and access permissions. Ensure that
            all users have the appropriate access to the features and
            functionalities they need.
          </p>
        </div>
        <div className="flex items-center justify-end">
          <Button color="blue" onClick={() => setShowUserCreateModal(true)}>
            <UserPlusIcon className="mr-2 size-5" />
            Create new user
          </Button>
        </div>
        <UserTable refresh={refresh} />
      </div>
      <UserCreateModal
        show={showUserCreateModal}
        onDismiss={() => setShowUserCreateModal(false)}
        onFormSubmitSuccess={handleAddApplicationSubmitSuccess}
        onFormSubmitFailure={() => setShowUserCreateModal(false)}
      />
    </main>
  );
}

export default UsersPage;
