import React, { createContext, useContext, useState, ReactNode } from "react";
import ErrorToast from "../components/ErrorToast";
import SuccessToast from "../components/SuccessToast";

interface ToastContextType {
  showToast: (type: string, message: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toast, setToast] = useState<{ type: string; message: string } | null>(
    null,
  );

  const showToast = (type: string, message: string) => {
    setToast({ type, message });
    setTimeout(() => setToast(null), 5000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast && toast.type === "error" && (
        <ErrorToast show={true} message={toast.message} />
      )}
      {toast && toast.type === "success" && (
        <SuccessToast show={true} message={toast.message} />
      )}
    </ToastContext.Provider>
  );
};
