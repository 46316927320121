import { Label, Select, TextInput, Textarea } from "flowbite-react";
import { DocumentTextIcon } from "@heroicons/react/24/solid";
import { ApplicationFormData } from "./AddApplicationModal";
import { snakeToUpperCase } from "../common/utils";

interface ApplicationStepContentProps {
  applicationFormData: ApplicationFormData;
}

function AddApplicationStep4({
  applicationFormData,
}: ApplicationStepContentProps) {
  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      <div className="grid md:col-span-2">
        <p className="text-base font-normal text-gray-500 dark:text-slate-300">
          Carefully review the applications below. Upon submission, application
          information cannot be changed and modified.
        </p>
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-medium text-gray-800 dark:text-white">
          Application Information
        </p>
      </div>
      <div>
        <Label
          htmlFor="applicationType"
          value="Application type"
          className="mb-2"
        />
        <Select
          id="applicationType"
          name="applicationType"
          value={applicationFormData.applicationType}
          disabled
        >
          <option value={applicationFormData.applicationType}>
            {snakeToUpperCase(applicationFormData.applicationType)}
          </option>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="assistanceCategory"
          value="Assistance category"
          className="mb-2"
        />
        <Select
          id="assistanceCategory"
          name="assistanceCategory"
          value={applicationFormData.assistanceCategory}
          disabled
        >
          <option value={applicationFormData.assistanceCategory}>
            {snakeToUpperCase(applicationFormData.assistanceCategory)}
          </option>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="clientCategory"
          value="Client category"
          className="mb-2"
        />
        <Select
          id="clientCategory"
          name="clientCategory"
          value={applicationFormData.clientCategory}
          disabled
        >
          <option value={applicationFormData.clientCategory}>
            {snakeToUpperCase(applicationFormData.clientCategory)}
          </option>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryCategory"
          value="Assistance category"
          className="mb-2"
        />
        <Select
          id="beneficiaryCategory"
          name="beneficiaryCategory"
          value={applicationFormData.beneficiaryCategory}
          disabled
        >
          <option value={applicationFormData.assistanceCategory}>
            {snakeToUpperCase(applicationFormData.assistanceCategory)}
          </option>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryRelationship"
          value="Relationship to Beneficiary"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryRelationship"
          name="beneficiaryRelationship"
          type="text"
          value={applicationFormData.beneficiaryRelationship}
          disabled
        />
      </div>
      <div>
        <Label
          htmlFor="financialMode"
          value="Financial Mode"
          className="mb-2"
        />
        <Select
          id="financialMode"
          name="financialMode"
          value={applicationFormData.financialMode}
          disabled
        >
          <option value={applicationFormData.financialMode}>
            {snakeToUpperCase(applicationFormData.financialMode)}
          </option>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="assistanceSource"
          value="Assistance source"
          className="mb-2"
        />
        <Select
          id="assistanceSource"
          name="assistanceSource"
          value={applicationFormData.assistanceSource}
          disabled
        >
          <option value={applicationFormData.assistanceSource}>
            {snakeToUpperCase(applicationFormData.assistanceSource)}
          </option>
        </Select>
      </div>
      <div>
        <Label htmlFor="amount" value="Amount" className="mb-2" />
        <TextInput
          id="amount"
          name="amount"
          type="text"
          value={applicationFormData.amount}
          disabled
        />
      </div>
      <div className="md:col-span-2">
        <Label htmlFor="purpose" value="Purpose" className="mb-2" />
        <Textarea
          id="purpose"
          name="purpose"
          rows={4}
          value={applicationFormData.purpose}
          disabled
        />
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-medium text-gray-800 dark:text-white">
          Beneficiary Personal Information
        </p>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryFirstName"
          value="First name"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryFirstName"
          name="beneficiaryFirstName"
          type="text"
          value={applicationFormData.beneficiaryFirstName}
          disabled
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryFirstName"
          value="Last name"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryLastName"
          name="beneficiaryLastName"
          type="text"
          value={applicationFormData.beneficiaryLastName}
          disabled
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryMiddleName"
          value="Middle name"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryMiddleName"
          name="beneficiaryMiddleName"
          type="text"
          value={applicationFormData.beneficiaryMiddleName}
          disabled
        />
      </div>
      <div>
        <Label htmlFor="beneficiarySex" value="Gender" className="mb-2" />
        <Select
          id="beneficiarySex"
          name="beneficiarySex"
          value={applicationFormData.beneficiarySex}
          disabled
        >
          <option value={applicationFormData.beneficiarySex} disabled>
            {applicationFormData.beneficiarySex}
          </option>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryBirthdate"
          value="Birthdate"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryBirthdate"
          name="beneficiaryBirthdate"
          type="text"
          value={applicationFormData.beneficiaryBirthdate}
          disabled
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryStatus"
          value="Civil status"
          className="mb-2"
        />
        <Select
          id="beneficiaryStatus"
          name="beneficiaryStatus"
          value={applicationFormData.beneficiaryStatus}
          disabled
        >
          <option value={applicationFormData.beneficiaryStatus}>
            {applicationFormData.beneficiaryStatus}
          </option>
        </Select>
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-medium text-gray-800 dark:text-white">
          Beneficiary Contact Information
        </p>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryContact"
          value="Contact Number"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryContact"
          name="beneficiaryContact"
          type="text"
          value={applicationFormData.beneficiaryContact}
          disabled
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryBarangay"
          value="Barangay"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryBarangay"
          name="beneficiaryBarangay"
          type="text"
          value={applicationFormData.beneficiaryBarangay}
          disabled
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryCity"
          value="City / Municipality"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryCity"
          name="beneficiaryCity"
          type="text"
          value={applicationFormData.beneficiaryCity}
          disabled
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryProvince"
          value="Province / District"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryProvince"
          name="beneficiaryProvince"
          type="text"
          value={applicationFormData.beneficiaryProvince}
          disabled
        />
      </div>
      <div>
        <Label htmlFor="beneficiaryRegion" value="Region" className="mb-2" />
        <TextInput
          id="beneficiaryRegion"
          name="beneficiaryRegion"
          type="text"
          value={applicationFormData.beneficiaryRegion}
          disabled
        />
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-medium text-gray-800 dark:text-white">
          Beneficiary Occupational Information
        </p>
      </div>
      <div>
        <Label
          htmlFor="beneficiaryOccupation"
          value="Job / Occupation"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryOccupation"
          name="beneficiaryOccupation"
          type="text"
          value={applicationFormData.beneficiaryOccupation}
          disabled
        />
      </div>
      <div>
        <Label
          htmlFor="beneficiaryIncome"
          value="Monthly income"
          className="mb-2"
        />
        <TextInput
          id="beneficiaryIncome"
          name="beneficiaryIncome"
          type="text"
          value={applicationFormData.beneficiaryIncome}
          disabled
        />
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-medium text-gray-800 dark:text-white">
          Documents
        </p>
      </div>
      {applicationFormData.files &&
        Object.keys(applicationFormData.files).map((key) => (
          <div
            key={key}
            className="flex flex-col items-center justify-center space-y-2 px-4"
          >
            <p className="mb-2 w-full text-sm font-medium text-gray-800 dark:text-white">
              {snakeToUpperCase(key)}
            </p>
            <DocumentTextIcon className="size-16 fill-none stroke-gray-600 dark:stroke-slate-300" />
            <p className="text-center text-sm font-light text-gray-600 dark:text-slate-300">
              {applicationFormData.files[key].name}
            </p>
          </div>
        ))}
    </div>
  );
}

export default AddApplicationStep4;
