import { useState } from "react";
import { Dropdown, Modal, Button } from "flowbite-react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import {
  ArchiveBoxArrowDownIcon,
  ArchiveBoxXMarkIcon,
  BanknotesIcon,
  DocumentCheckIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  Square3Stack3DIcon,
  UserGroupIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { RowData } from "./ApplicationTable";
import { useAuth } from "../common/AuthContext";
import { useToast } from "../common/ToastContext";
import { UserRole, ApplicationStatus } from "../common/enums";
import PdfViewerModal from "./PdfViewerModal";
import InterviewModal from "./InterviewModal.";
import api from "../common/api";

interface ActionDropdownProps {
  row: RowData;
  updateRowStatus: (applicationNumber: string, newStatus: string) => void;
  onDelete: (applicationNumber: string) => void;
}

function ApplicationActionDropdown({
  row,
  updateRowStatus,
  onDelete,
}: ActionDropdownProps) {
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [modalActionStatus, setModalActionStatus] =
    useState<ApplicationStatus | null>(null);
  const { userdata } = useAuth();
  const { showToast } = useToast();

  const updateStatus = async (status: ApplicationStatus | null) => {
    if (status === null) {
      return;
    }
    if (status === ApplicationStatus.deleted) {
      try {
        await api.delete(`/application/${row.applicationNumber}`);
        onDelete(row.applicationNumber);
        showToast(
          "success",
          `Application <${row.applicationNumber}> has been deleted`,
        );
      } catch (error: any) {
        console.log(error);
        showToast(
          "error",
          `Error ${status} application: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
        );
      }
      return;
    }
    try {
      await api.patch(
        `/application/${row.applicationNumber}?new_status=${status}`,
      );
      updateRowStatus(row.applicationNumber, status);
      showToast(
        "success",
        `Application <${row.applicationNumber}> has been ${status}`,
      );
    } catch (error: any) {
      console.log(error);
      showToast(
        "error",
        `Error ${status} application: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
      );
    }
    setModalActionStatus(null);
    setShowConfirmModal(false);
  };

  const updateStatusWithConfirmation = (
    action: string,
    status: ApplicationStatus,
  ) => {
    setActionType(action);
    setShowConfirmModal(true);
    setModalActionStatus(status);
  };

  return (
    <>
      <Dropdown
        label={<EllipsisVerticalIcon className="size-5" />}
        inline={true}
        arrowIcon={false}
        placement="left-start"
      >
        <Dropdown.Item icon={EyeIcon} onClick={() => setShowPdfModal(true)}>
          View application
        </Dropdown.Item>
        <Dropdown.Item
          className={
            userdata &&
            [UserRole.user, UserRole.admin].includes(
              userdata.role as UserRole,
            ) &&
            row.status !== ApplicationStatus.canceled
              ? ""
              : "hidden"
          }
          icon={ArchiveBoxXMarkIcon}
          onClick={() =>
            updateStatusWithConfirmation("cancel", ApplicationStatus.canceled)
          }
        >
          Cancel Application
        </Dropdown.Item>
        <Dropdown.Item
          className={
            userdata &&
            [UserRole.staff, UserRole.officer, UserRole.admin].includes(
              userdata.role as UserRole,
            )
              ? ""
              : "hidden"
          }
          icon={UserGroupIcon}
          onClick={() => setShowInterviewModal(true)}
        >
          Schedule an interview
        </Dropdown.Item>
        <Dropdown.Item
          className={
            userdata &&
            [UserRole.staff, UserRole.officer, UserRole.admin].includes(
              userdata.role as UserRole,
            )
              ? ""
              : "hidden"
          }
          icon={DocumentCheckIcon}
          onClick={() => updateStatus(ApplicationStatus.approved)}
        >
          Approve Application
        </Dropdown.Item>
        <Dropdown.Item
          className={
            userdata &&
            [UserRole.staff, UserRole.officer, UserRole.admin].includes(
              userdata.role as UserRole,
            )
              ? ""
              : "hidden"
          }
          icon={ArchiveBoxArrowDownIcon}
          onClick={() => updateStatus(ApplicationStatus.rejected)}
        >
          Reject Application
        </Dropdown.Item>
        <Dropdown.Item
          className={
            userdata &&
            [UserRole.staff, UserRole.officer, UserRole.admin].includes(
              userdata.role as UserRole,
            )
              ? ""
              : "hidden"
          }
          icon={BanknotesIcon}
          onClick={() => updateStatus(ApplicationStatus.released)}
        >
          Set as released
        </Dropdown.Item>
        <Dropdown.Item
          className={
            userdata &&
            [UserRole.staff, UserRole.officer, UserRole.admin].includes(
              userdata.role as UserRole,
            )
              ? ""
              : "hidden"
          }
          icon={Square3Stack3DIcon}
          onClick={() => updateStatus(ApplicationStatus.completed)}
        >
          Set as completed
        </Dropdown.Item>
        <Dropdown.Item
          className={
            userdata && userdata.role === UserRole.admin ? "" : "hidden"
          }
          icon={TrashIcon}
          onClick={() =>
            updateStatusWithConfirmation("delete", ApplicationStatus.deleted)
          }
        >
          Delete application
        </Dropdown.Item>
      </Dropdown>
      <Modal
        show={showConfirmModal}
        size="md"
        onClose={() => setShowConfirmModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
              <ExclamationTriangleIcon className="size-6 text-red-600"></ExclamationTriangleIcon>
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                className="text-base font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Confirm Action
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  This action cannot be undo. Are you sure you want to{" "}
                  {actionType} this application?
                </p>
              </div>
            </div>
          </div>
          <div className="mt-2 flex flex-row justify-center space-x-4 px-6 py-3 lg:justify-end">
            <Button color="gray" onClick={() => setShowConfirmModal(false)}>
              Cancel
            </Button>
            <Button
              color="failure"
              onClick={() => updateStatus(modalActionStatus)}
            >
              Proceed
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <PdfViewerModal
        show={showPdfModal}
        title={row.applicationNumber}
        document={`/download/${row.applicationNumber}`}
        onDismiss={() => {
          setShowPdfModal(false);
        }}
      />
      <InterviewModal
        applicationNumber={row.applicationNumber}
        show={showInterviewModal}
        onDismiss={() => setShowInterviewModal(false)}
        onSubmitSuccess={() => setShowInterviewModal(false)}
        onSubmitFailure={() => setShowInterviewModal(false)}
      />
    </>
  );
}

export default ApplicationActionDropdown;
