import { useState, useEffect } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { parseISO, addMinutes } from "date-fns";
import { useToast } from "../common/ToastContext";
import api from "../common/api";
import { stringToColor } from "../common/utils";

interface ScheduleApiData {
  uuid: string;
  interview_location: string;
  interview_at: string;
}

interface CalendarEvent {
  event_id: string | number;
  title: string;
  start: Date;
  end: Date;
  color: string;
}

function ScheduleCalendar() {
  const { showToast } = useToast();
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    async function setCalendarEvents() {
      let apiResponse = { data: [] };
      try {
        apiResponse = await api.get("/interviews");
      } catch (error: any) {
        console.log(error);
        showToast(
          "error",
          `Error retrieving interviews: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
        );
      }
      const calendarEvents: CalendarEvent[] = [];
      apiResponse.data.forEach((data: ScheduleApiData, index: number) => {
        const startDate = parseISO(data.interview_at);
        const endDate = addMinutes(startDate, 30);
        const calendarEvent: CalendarEvent = {
          event_id: index,
          title: `${data.uuid} Interview @ ${data.interview_location}`,
          start: startDate,
          end: endDate,
          color: stringToColor(data.uuid),
        };
        calendarEvents.push(calendarEvent);
      });
      setEvents(calendarEvents);
    }
    setCalendarEvents();
  }, [showToast]);

  return (
    <Scheduler
      events={events}
      disableViewer
      view="month"
      editable={false}
      onEventClick={() => {
        console.log("onEventClick");
      }}
    />
  );
}

export default ScheduleCalendar;
