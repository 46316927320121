import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ErrorToast from "./ErrorToast";
import SuccessToast from "./SuccessToast";

interface ToastMessage {
  type: "error" | "success";
  message: string;
}

function ToastManager() {
  const location = useLocation();
  const [toast, setToast] = useState<ToastMessage | null>(null);

  useEffect(() => {
    const toast = location.state?.toast as ToastMessage | undefined;

    if (toast) {
      setToast(toast);

      const timer = setTimeout(() => {
        setToast(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [location.state]);

  return (
    <>
      {toast && toast.type === "error" && (
        <ErrorToast show={true} message={toast.message} />
      )}
      {toast && toast.type === "success" && (
        <SuccessToast show={true} message={toast.message} />
      )}
    </>
  );
}

export default ToastManager;
