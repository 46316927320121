enum UserRole {
  user = "user",
  staff = "staff",
  officer = "officer",
  admin = "admin",
}

enum ApplicationStatus {
  submitted = "submitted",
  canceled = "canceled",
  interview = "interview",
  approved = "approved",
  rejected = "rejected",
  released = "released",
  completed = "completed",
  deleted = "deleted",
}
export { UserRole, ApplicationStatus };
