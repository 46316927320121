import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./common/AuthContext";
import { ToastProvider } from "./common/ToastContext";
import { AppThemeProvider } from "./common/ThemeContext";
import ToastManager from "./components/ToastManager";
import ProtectedRoute from "./common/ProtectedRoute";
import UnprotectedRoute from "./common/UnprotectedRoute";
import Mainpage from "./pages/Mainpage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import Dashboard from "./pages/Dashboard";
import UsersPage from "./pages/UsersPage";
import ProfilePage from "./pages/ProfilePage";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppThemeProvider>
          <ToastProvider>
            <ToastManager />
            <Routes>
              <Route element={<UnprotectedRoute />}>
                <Route path="/" element={<Mainpage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/profile" element={<ProfilePage />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ToastProvider>
        </AppThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
