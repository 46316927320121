import {
  Avatar,
  Navbar,
  DarkThemeToggle,
  Dropdown,
  Button,
} from "flowbite-react";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../common/AuthContext";
import { useTheme } from "../common/ThemeContext";
import { titleCase } from "../common/utils";
import { useState } from "react";
import NotificationDropdown from "./NotificationDropdown";

function NavigationBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userdata } = useAuth();
  const { toggleTheme } = useTheme();
  useState(false);

  const navlinks = [
    { link: "/", label: "Home", role: ["unauthenticated"] },
    { link: "/login", label: "Login", role: ["unauthenticated"] },
    { link: "/register", label: "Register", role: ["unauthenticated"] },
    {
      link: "/dashboard",
      label: "Dashboard",
      role: ["user", "staff", "officer", "admin"],
    },
    { link: "/reports", label: "Reports", role: ["staff", "officer", "admin"] },
    { link: "/users", label: "Users", role: ["admin"] },
  ];

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        <img src="/logo.png" className="mr-3 h-6 sm:h-9" alt="MSWDash Logo" />
        <span className="self-center whitespace-nowrap text-xl font-bold dark:text-white">
          MSWDash
        </span>
      </Navbar.Brand>
      <div className="flex space-x-1 md:order-2">
        {userdata ? (
          <div className={`relative mx-auto w-max md:flex`}>
            <Dropdown
              label=""
              dismissOnClick={false}
              className="py-0"
              renderTrigger={() => (
                <Button
                  color="none"
                  className="dark-hover:bg-none px-0 hover:bg-gray-200 dark:bg-none"
                >
                  <BellAlertIcon className="size-5 fill-gray-500 stroke-gray-500" />
                </Button>
              )}
            >
              <NotificationDropdown />
            </Dropdown>
          </div>
        ) : (
          ""
        )}

        <DarkThemeToggle onClick={() => toggleTheme()} className="mr-5" />
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar
              className={userdata ? "visible" : "hidden"}
              alt="User settings"
              img={`/profiles/${userdata?.profile || "1"}.jpg`}
              rounded
            />
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">
              <span className="mr-2 font-semibold">
                [{titleCase(userdata?.role || "Unknown Role")}]
              </span>
              {userdata?.firstName || "Unknown"} {userdata?.lastName || "Name"}{" "}
            </span>
            <span className="block truncate text-sm font-medium">
              {userdata?.email}
            </span>
          </Dropdown.Header>
          <Dropdown.Item onClick={() => navigate("/profile")}>
            Profile
          </Dropdown.Item>
          <Dropdown.Item>Settings</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => navigate("/logout")}>
            Sign out
          </Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        {navlinks
          .filter((link) =>
            link.role.includes(userdata?.role || "unauthenticated"),
          )
          .map((navlink, index) => (
            <p
              key={index}
              className={
                isActive(navlink.link)
                  ? "bg-blue-700 text-white dark:text-white md:bg-transparent md:text-blue-700"
                  : "border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:bg-transparent md:dark:hover:text-white"
              }
            >
              <Link to={navlink.link}>{navlink.label}</Link>
            </p>
          ))}
        {userdata ? (
          <p
            className={`block md:hidden ${
              isActive("/notifications")
                ? "bg-blue-700 text-white dark:text-white md:bg-transparent md:text-blue-700"
                : "border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:bg-transparent md:dark:hover:text-white"
            }`}
          >
            <Link to="/notifications">Notifications</Link>
          </p>
        ) : (
          ""
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
