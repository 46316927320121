import { useState, useEffect, useMemo } from "react";
import { Button, Modal } from "flowbite-react";
import { BackwardIcon, ForwardIcon } from "@heroicons/react/24/solid";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import api from "../common/api";

pdfjs.GlobalWorkerOptions.workerSrc = "https://unpkg.com/pdfjs-dist@4.6.82/build/pdf.worker.min.mjs";

interface PdfViewerModalProps {
  show: boolean;
  title: string;
  document: string;
  onDismiss: () => void;
}

function PdfViewerModal(props: PdfViewerModalProps) {
  const [showModal, setShowModal] = useState(props.show);
  const [modalTitle, setModalTitle] = useState(props.title);
  const [numPages, setNumPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pdfData, setPdfData] = useState<Blob | null>(null);

  useEffect(() => {
    setShowModal(props.show);
    setModalTitle(props.title);
  }, [props.show, props.title]);

  useEffect(() => {
    async function fetchPdf() {
      try {
        const response = await api.get(`${props.document}?combined=true`, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        });
        setPdfData(response.data);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    }

    if (props.document) {
      fetchPdf();
    }
  }, [props.document]);

  const memoizedPdfData = useMemo(() => pdfData, [pdfData]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const nextPage = () => {
    if (currentPage >= 1 && currentPage < numPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <Modal show={showModal} size="2xl" onClose={props.onDismiss} popup>
      <Modal.Header className="px-6 py-4">{modalTitle}</Modal.Header>
      <div className="sticky top-0 flex w-full items-center justify-center py-2.5">
        <div className="flex w-fit flex-row items-center justify-center space-x-4 rounded-xl bg-slate-600 p-2">
          <Button
            size="xs"
            color="none"
            onClick={previousPage}
            disabled={currentPage <= 1}
          >
            <BackwardIcon className="size-4 fill-slate-100 stroke-slate-100" />
          </Button>
          <p className="whitespace-pre text-center text-sm font-medium text-white">
            Page
            <span className="mx-2 font-semibold">{currentPage}</span>
            of <span className="ml-2 font-semibold">{numPages}</span>
          </p>
          <Button
            size="xs"
            color="none"
            onClick={nextPage}
            disabled={currentPage === numPages}
          >
            <ForwardIcon className="size-4 fill-slate-100 stroke-slate-100" />
          </Button>
        </div>
      </div>
      <Modal.Body>
        <div className="flex justify-center">
          <Document
            renderMode="canvas"
            file={memoizedPdfData}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={currentPage} />
          </Document>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PdfViewerModal;
