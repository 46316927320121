import React, { useState } from "react";
import { useEffect } from "react";
import { useAuth } from "./AuthContext";

import { Navigate, Outlet } from "react-router-dom";

interface UnprotectedRouteProps {
  redirectPath?: string;
}

const UnprotectedRoute: React.FC<UnprotectedRouteProps> = ({
  redirectPath = "/dashboard",
}) => {
  const { check } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkIfAuthenticated = async () => {
      const authenticated = await check();
      setIsAuthenticated(authenticated);
      setLoading(false);
    };
    checkIfAuthenticated();
  });

  if (loading) {
    return <></>;
  }

  if (isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default UnprotectedRoute;
