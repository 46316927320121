import { useState } from "react";
import { Button } from "flowbite-react";
import { FolderPlusIcon } from "@heroicons/react/24/solid";
import { useAuth } from "../common/AuthContext";
import NavigationBar from "../components/NavigationBar";
import ProfileIncompleteModal from "../components/ProfileIncompleteModal";
import ApplicationTable from "../components/ApplicationTable";
import ScheduleCalendar from "../components/ScheduleCalendar";
import AddApplicationModal from "../components/AddApplicationModal";

function Dashboard() {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { userdata, checkProfile } = useAuth();

  const handleApplyAction = async () => {
    const profileComplete = await checkProfile();
    if (!profileComplete) {
      setShowConfirmModal(true);
      return;
    }
    setShowApplyModal(true);
  };

  const handleAddApplicationSubmitSuccess = () => {
    setShowApplyModal(false);
    setRefresh(!refresh);
  };

  return (
    <main className="flex min-h-screen flex-col dark:bg-gray-800">
      <NavigationBar />
      <div className="container mx-auto flex min-h-screen flex-col justify-start space-y-8 p-6 lg:pt-14">
        <div className="flex w-full flex-col space-y-2">
          <p className="text-xl font-extrabold text-gray-900 dark:text-white lg:text-3xl">
            Application Status Tracker
          </p>
          <p className="text-base font-normal text-gray-500 dark:text-slate-100 lg:text-lg">
            Easily monitor the progress of your assistance application. Stay
            informed with real-time updates on the status of your request, from
            submission to approval.
          </p>
        </div>
        <div className="flex items-center justify-end">
          <Button
            color="blue"
            className={
              userdata && userdata.role === "user" ? "block" : "hidden"
            }
            onClick={() => handleApplyAction()}
          >
            <FolderPlusIcon className="mr-2 size-5" />
            Apply for an assistance
          </Button>
        </div>
        <ApplicationTable refresh={refresh} />
        <div className="h-6"></div>
        <div className="flex w-full flex-col space-y-2">
          <p className="text-xl font-extrabold text-gray-900 dark:text-white lg:text-3xl">
            Interview Schedule Tracker
          </p>
          <p className="text-base font-normal text-gray-500 dark:text-slate-100 lg:text-lg">
            Check out the schedule below for details on our upcoming interviews.
            Please ensure you're prepared and on time. If you have any questions
            or need to reschedule, feel free to reach out to us.
          </p>
        </div>
        <ScheduleCalendar />
      </div>
      <ProfileIncompleteModal show={showConfirmModal} />
      <AddApplicationModal
        show={showApplyModal}
        onDismiss={() => setShowApplyModal(false)}
        onFormSubmitSuccess={handleAddApplicationSubmitSuccess}
        onFormSubmitFailure={() => setShowApplyModal(false)}
      />
    </main>
  );
}

export default Dashboard;
