import { useState, useEffect } from "react";
import { Modal } from "flowbite-react";

interface SelectProfileModalProps {
  show: boolean;
  onDismiss: () => void;
  onProfileSelect: (value: string) => void;
}

function SelectProfileModal(props: SelectProfileModalProps) {
  const [showModal, setShowModal] = useState(props.show);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  return (
    <Modal show={showModal} size="2xl" onClose={() => props.onDismiss()} popup>
      <Modal.Header className="px-6 py-4">Select Profile Picture</Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-3 justify-items-center gap-8">
          {[...Array(9)].map((_, i) => (
            <div key={i} onClick={() => props.onProfileSelect(`${i + 1}`)}>
              <img
                src={`/profiles/${i + 1}.jpg`}
                alt={`Profile ${i + 1}`}
                className="size-24 cursor-pointer rounded-full hover:brightness-75"
              />
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SelectProfileModal;
