import { useState } from "react";
import { Dropdown } from "flowbite-react";
import {
  EllipsisVerticalIcon,
  UserIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { RowData } from "./UserTable";
import UserUpdateRoleModal from "./UserUpdateRoleModal";
import UserDeleteModal from "./UserDeleteModal";
import { useToast } from "../common/ToastContext";
import api from "../common/api";

interface ActionDropdownProps {
  row: RowData;
  onUpdate: (id: string, role: string) => void;
  onDelete: (id: string) => void;
}

function UserActionDropdown(props: ActionDropdownProps) {
  const { showToast } = useToast();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleConfirmUpdate = async (value: string) => {
    try {
      await api.patch(`/user/${props.row.id}?role=${value}`);
      props.onUpdate(props.row.id, value);
      showToast("success", `User <${props.row.email}> role has been updated`);
    } catch (error: any) {
      console.log(error);
      showToast(
        "error",
        `Error updating user: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
      );
    } finally {
      setShowUpdateModal(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/user/${props.row.id}`);
      props.onDelete(props.row.id);
      showToast("success", `User <${props.row.email}> role has been deleted`);
    } catch (error: any) {
      showToast(
        "error",
        `Error deleting user: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
      );
    } finally {
      setShowConfirmModal(false);
    }
  };

  return (
    <>
      <Dropdown
        label={<EllipsisVerticalIcon className="size-5" />}
        inline={true}
        arrowIcon={false}
      >
        <Dropdown.Item icon={UserIcon} onClick={() => setShowUpdateModal(true)}>
          Update Role
        </Dropdown.Item>
        <Dropdown.Item
          icon={TrashIcon}
          onClick={() => setShowConfirmModal(true)}
        >
          Delete User
        </Dropdown.Item>
      </Dropdown>
      <UserUpdateRoleModal
        show={showUpdateModal}
        onConfirm={handleConfirmUpdate}
        onDismiss={() => setShowUpdateModal(false)}
        initialRole={props.row.role}
      />
      <UserDeleteModal
        show={showConfirmModal}
        onConfirm={handleConfirmDelete}
        onDismiss={() => setShowConfirmModal(false)}
      />
    </>
  );
}

export default UserActionDropdown;
