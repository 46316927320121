import { useState, useEffect } from "react";
import { Toast } from "flowbite-react";
import { HiShieldExclamation } from "react-icons/hi";

interface ErrorToastProps {
  show: boolean;
  message: string;
}

function ErrorToast(props: ErrorToastProps) {
  const [showToast, setShowToast] = useState(props.show);

  useEffect(() => {
    setShowToast(props.show);
  }, [props.show]);

  return (
    <Toast
      className={
        showToast ? "animate-slide-in fixed right-0 top-8 lg:top-16" : "hidden"
      }
    >
      <div className="inline-flex size-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
        <HiShieldExclamation className="size-5" />
      </div>
      <div className="ml-3 text-sm font-normal">{props.message}</div>
      <Toast.Toggle />
    </Toast>
  );
}

export default ErrorToast;
