import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../common/AuthContext";
import { useToast } from "../common/ToastContext";

import { Button, Card, Checkbox, Label, TextInput } from "flowbite-react";
import { Link } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";
import NavigationBar from "../components/NavigationBar";
import LinkIcon from "../components/LinkIcon";

function LoginPage() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      const response = await login(username, password);
      setIsLoading(false);
      if (response.success) {
        navigate("/dashboard");
        showToast("success", "Login successfully!");
        return;
      }
      showToast("error", `Login failed: ${response.detail}`);
    } catch (error: any) {
      console.log(error);
      showToast(
        "error",
        `Login failed: ${error.response?.data.detail[0].msg || error.response?.data.detail || "Unknown error"}`,
      );
    }
  };

  return (
    <main className="flex min-h-screen flex-col dark:bg-gray-800">
      <NavigationBar />
      <div className="container mx-auto flex min-h-screen flex-col justify-start space-y-8 p-6 lg:flex-row lg:space-x-8 lg:pt-14">
        <div className="flex w-full flex-col space-y-8">
          <p className="text-6xl font-extrabold leading-none text-gray-900 dark:text-white">
            MSWDash
          </p>
          <p className="text-xl font-normal text-gray-500 dark:text-slate-100">
            A web-based application for enhancing MSWDO Intiatives in Disaster
            Relief and Poverty Alleviation in Torrijos, Marinduque
          </p>
          <LinkIcon href="/manual" icon={HiOutlineArrowRight}>
            Read app manual
          </LinkIcon>
          <img
            className="hidden size-96 lg:block"
            src="/login.svg"
            alt="Login"
          />
        </div>
        <Card className="h-fit w-full py-4">
          <form
            onSubmit={handleSubmit}
            className="flex max-w-full flex-col gap-4"
          >
            <p className="text-2xl font-bold dark:text-white">Sign In</p>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Your email or username" />
              </div>
              <TextInput
                id="email"
                type="email"
                name="username"
                placeholder="johndoe@example.com"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="password" value="Your password" />
              </div>
              <TextInput
                id="password"
                type="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="flex items-center gap-2">
              <Checkbox id="remember" />
              <Label htmlFor="remember">Remember this device</Label>
            </div>
            <p className="my-2 text-sm font-medium dark:text-white">
              Not registered yet?
              <Link
                to="/register"
                className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-700"
              >
                Create an account
              </Link>
            </p>
            <Button color={"blue"} type="submit" isProcessing={isLoading}>
              Login to your account
            </Button>
          </form>
        </Card>
      </div>
    </main>
  );
}

export default LoginPage;
